<template>
    <div class="col-sm-9 ps-4">
        <h3 class="my-3">Rx:</h3>
        <div class="border p-4 bg-light" style="min-height: 50vh">
            <div class="medicine-item">
                <div class="table-responsive prescription-table">
                    <table class="table table-striped">
                        <tr v-for="(medicine,index) in $parent.form.medicines" :key="index">
                            <td style="vertical-align:top; padding-top: 12px; width: 100px">Medicine {{ ++index }}</td>
                            <td width="auto" style="vertical-align:top" class="w-300">
                                <multiselect
                                    :class="{ 'is-invalid': $parent.errors[`medicines.${index}.medicine`] }"
                                    v-model="medicine.medicine"
                                    :options="new_transaction_items"
                                    @search-change="getMedicines"
                                    :taggable="true"
                                    tag-placeholder="Add new Medicine"
                                    placeholder="Select or add the medicine"
                                    :id="--index"
                                    @tag="addAbility"/>
                                <div v-if="$parent.errors[`medicines.${index}.medicine`]"
                                     class="invalid-feedback p-0 m-0">
                                    {{ $parent.errors[`medicines.${index}.medicine`][0] }}
                                </div>
                            </td>
                            <td style="vertical-align:top; padding-top: 12px; width: 15px">
                                Dose
                            </td>
                            <td width="150px" style="vertical-align:top">
                                <input :class="{ 'is-invalid': $parent.errors[`medicines.${index}.dose`] }" v-model="medicine.dose" type="text" class="form-control">
                                <div v-if="$parent.errors[`medicines.${index}.dose`]"
                                     class="invalid-feedback p-0 m-0">
                                    {{ $parent.errors[`medicines.${index}.dose`][0] }}
                                </div>
                            </td>
                            <td style="vertical-align:top; padding-top: 12px; width: 15px">
                                Days
                            </td>
                            <td width="70px" style="vertical-align:top">
                                <input :class="{ 'is-invalid': $parent.errors[`medicines.${index}.duration`] }" v-model="medicine.duration" type="text" class="form-control">
                                <div v-if="$parent.errors[`medicines.${index}.duration`]"
                                     class="invalid-feedback p-0 m-0">
                                    {{ $parent.errors[`medicines.${index}.duration`][0] }}
                                </div>
                            </td>
                            <td>
                                <i type="button" @click="removeMedicineItem(index)" class="text-danger fa fa-trash"></i>
                            </td>
                        </tr>
                    </table>
                </div>
                <div type="button"
                     class="add-item bg-primary py-2 px-3 text-white rounded mt-2 d-inline" @click="addMedicineItem">+
                </div>
            </div>
        </div>
        <div class="mt-3 text-center d-flex gap-2 justify-content-center prescription-btn">
            <div @click="$parent.editMode ? $parent.update('preview') : $parent.save('preview')"
                 class="bg-primary btn mt-3 px-5 py-3 text-light">
                Preview
            </div>
            <div type="submit" @click="$parent.editMode ? $parent.update('save') : $parent.save('save')"
                 class="bg-primary btn mt-3 px-5 py-3 text-light mx-3">{{ $parent.editMode ? 'Update' : 'Save' }}
            </div>
            <div @click="$router.go(-1)" class="bg-secondary btn mt-3 px-5 py-3 text-light">Cancel</div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Multiselect         from 'vue-multiselect';

export default {
    name      : "RightSection",
    components: {Multiselect},
    data      : () => ({
        medicines: [],
        medicine : {}
    }),
    mounted() {
        this.getMedicines();
    },
    computed  : {
        selected_item_ids() {
            return this.$parent.form.medicines.map((item, index) => {
                return item.medicine;
            })
        },
        new_transaction_items() {
            return this.medicines.filter(item => !this.selected_item_ids.includes(item));
        }
    },
    methods: {
        addAbility(newAbility, index) {
            this.$parent.form.medicines[index].medicine = newAbility;
        },
        //dropdown lists
        getMedicines: function (search) {
            let params = {
                search: search
            };
            ApiService.get('/dropdown/medicines', {params: params}).then((res) => {
                this.medicines = res.data
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        addMedicineItem() {
            let prescription_medicine = {
                medicine   : '',
                dose       : '',
                duration   : '',
            };
            this.$parent.form.medicines.push(prescription_medicine)
        },
        removeMedicineItem(index) {
            this.$parent.form.medicines.splice(index, 1)
        }
    }
}
</script>

<style scoped>
.table > :not(:first-child) {
    border-top: 0 solid currentColor;
}
.multiselect {
    padding: 0 !important;
}
</style>
